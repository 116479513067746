<template>
  <div class="upload-wrap">
    <div class="upload-container">
      <div class="upload-box">
        <div class="progress-container common-upload-box"
          v-if="modelInProgress === 'progressing' && !imageHandleComplete">
          <div class="progress-box">
            <hair-salon-bar :showInfo="false" />
          </div>
        </div>
        <!--  accept=".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG" -->
        <!--  :disabled="
            modelLoading ||
            modelInitializing ||
            modelLoadingError ||
            webcamEnabled
          " -->
        <a-upload-dragger :fileList="fileList" name="file" :showUploadList="false" @drop="handleFileDrop"
          id="commonUpload" @change="handleFileChange" :beforeUpload="handleBeforeUpload" :customRequest="customRequest"
          :accept="acceptFile" v-else>
          <div class="ant-upload-text">
            <h2 class="ant-upload-h2-text"> {{ props.uploadPlaceholder || 'Click or drop an image here to start' }}</h2>
            <p class="ant-upload-max-size-text" v-if="pathRoute === '/imageCompressor'">Max 10MB each</p>
          </div>

        </a-upload-dragger>
      </div>
    </div>
    <div class="imageSelect-container" v-if="!imageHandleComplete && !changeImage">
      <p class="image-select-tip">No image? Try one of these</p>
      <div class="image-container">
        <div class="image-box" v-for="item in imageSelectList" :key="item.id" @click="handleSelectImage(item)"
          :style="{ marginRight: item.marginRight + 'px' }" @mouseenter="handleMouseEnter" @mouseleave="handleMouseleave">
          <!-- v-if="currentSelectItem && currentSelectItem.id === item.id" -->
          <div class="mask-item" :style="{ width: item.width + 'px', height: item.height + 'px' }"></div>
          <img class="image-item" :src="item.iconSrc" :style="{ width: item.width + 'px', height: item.height + 'px' }" />
        </div>
      </div>
    </div>
  </div>
  <!-- modal -->
  <a-modal v-model:visible="visible" :footer="null" centered class="modal" width="600px" :maskClosable="false"
    @cancel="handleCancel">
    <template #title>
      <img :src="emailTitle" class="bg-image" />
      <div class="email-title">
        <img :src="emailIcon" />
        <div>SOMETHING AWESOME IS COMING!</div>
      </div>
    </template>
    <P>Hope you've enjoyed our product! We'll be launching more amazing features in the very near future. Get early
      access and freebie perks when we officially take flight.</P>
    <a-form>
      <a-form-item :validateStatus="emailValueStatus" class="input-item">
        <a-input v-model:value="emailValue" placeholder="Enter your email" class="email-input" />
      </a-form-item>
      <div v-if="emailValueStatus === 'error'" class="error-msg">{{ "Please enter a valid email address" }}</div>
      <a-button type="primary" class="email-submit" @click="handleSubmit">Submit</a-button>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, defineEmits, defineProps, watchEffect, watch, onMounted, computed, onUnmounted } from "vue";
import { compressorImage } from "../utils/buriedFunction/compressorImage";
import { reportData } from "../utils/buriedFunction/reportData";
import removeIcon1 from '../assets/example-images/remove_icon_1.webp'
import removeIcon2 from '../assets/example-images/remove_icon_2.webp'
import removeIcon3 from '../assets/example-images/remove_icon_3.webp'
import removeSrc1 from '../assets/example-images/remove_src_1.webp'
import removeSrc2 from '../assets/example-images/remove_src_2.webp'
import removeSrc3 from '../assets/example-images/remove_src_3.webp'
import emailTitle from '../assets/email_title.svg'
import emailIcon from '../assets/email_icon.svg'
import { useRoute } from 'vue-router'
import { message } from "ant-design-vue";
import { string } from "vue-types";
import store from '@/store';
import HairSalonBar from "./hairSalongBar.vue";

const emits = defineEmits(["changFileList"]);

const props = defineProps({
  modelLoading: String,
  modelInitializing: Boolean,
  imageUrls: Boolean,
  modelLoadingError: Boolean,
  webcamEnabled: Boolean,
  modelInProgress: Boolean || string,
  imageHandleComplete: Boolean,
  uploadPlaceholder: String,
  changeImage: Boolean,
  percentSpeedTime: {
    type: Number,
    default: 1000
  }
});

const imageSelectList = ref(
  props.imageUrls
    ? props.imageUrls
    : [
      {
        id: 1,
        iconSrc: removeIcon1,
        src: removeSrc1,
      },
      {
        id: 2,
        iconSrc: removeIcon2,
        src: removeSrc2,
      },
      {
        id: 3,
        iconSrc: removeIcon3,
        src: removeSrc3,
      },
    ]
);

const percent = ref(10);
const uploadError = ref(false);
const timer = ref(null)
const worker = ref(null)
const acceptFile = ref(null)
const isHover = ref(false)
const visible = ref(false);
const emailValue = ref("")
const emailValueStatus = ref("")
const isSendEmailFunc = () => {
  if (localStorage.getItem("isSendEmail") || sessionStorage.getItem("isSendEmail")) {
    return false
  } else {
    return true
  }
}

onMounted(() => {

  //
  const commonUploadDom = document.getElementById("commonUpload")
  commonUploadDom?.removeAttribute("capture")
  //判断当前设备类型
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    // 当前设备是移动设备
    acceptFile.value = "image/*"
  } else {
    acceptFile.value = ".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG"
  }

  progressInit()
});




onUnmounted(() => {
  clearInterval(timer.value)
})


// const openWorker = () => {
//   worker.value = new Worker()
//   worker.value.postMessage('开启线程===================')
//   worker.value.onmessage = (e) => {
//     console.log('onmessage========', e)
//     // progressInit()
//   }
// }

const progressInit = () => {
  console.log('percent.value', percent.value)
  if (timer.value) {
    return
  }

  timer.value = setInterval(() => {
    if (percent.value >= 95) {
      window.clearInterval(timer.value)
      return
    }
    percent.value += 1
  }, props.percentSpeedTime)
}

watch(() => props.modelInProgress, (modelInProgress, prevCount) => {
  console.log('modelInProgress, prevCount', modelInProgress, prevCount)
}, { immediate: true })
watchEffect(() => {

  if (props.modelInProgress === 'start') {

    console.log('props.modelInProgress----start', props.modelInProgress)
    percent.value = 10;
    // progressInit()

  }


  if (props.modelInProgress === 'progressing') {

    console.log('props.modelInProgress----progressing', props.modelInProgress)
    // progressInit()

  }

  if (props.modelInProgress === 'done') {
    // percent.value = 100;
    clearInterval(timer.value)
  }

});

const route = useRoute();
const path = computed(() => route.path)
const pathRoute = ref(path.value)
const customRequest = () => {
  console.log("上传");
};

const currentSelectItem = ref(null);
const handleFileChange = (value) => {
  handleBeforeUpload(value.file)
  if (uploadError.value) {
    return;
  }
  const fileList = value.fileList || [];
  // 将上传的数据
  uploadImageData(fileList[0].originFileObj, path.value)
  emits("changFileList", fileList);
  emits("changFile", value.file);
};

const uploadImageData = (file, value) => {
  switch (value) {
    case "/filter":
      compressorImage(file, "toolkits_effect_image_upload");
      break;
    case "/animation":
      compressorImage(file, "toolkits_animation_image_upload");
      break;
    case "/background-remover":
      compressorImage(file, "toolkits_bgremove_image_upload");
      break;
    case "/removeClothBackground":
      compressorImage(file, "toolkits_cloth_bgremove_image_upload");
      break;
    default:
      break;
  }
}

const handleFileDrop = (e) => {
  uploadError.value = false;
  const file = e.dataTransfer.files[0]

  store.dispatch('closeSide', { isCollapse: true })
  const acceptType = [
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/png",
    "image/PNG",
    "image/JPEG",
    "image/JPG",
  ];
  const isJpgOrPng = acceptType.includes(file.type);
  console.log("isJpgOrPng, isJpgOrPng");
  message.config({
    top: "80%",
    duration: 3,
    icon: () => {
      return false;
    },
  });
  if (!isJpgOrPng) {
    message.error("Only JPG , PNG , JPEG and WEBP images are supported.");
    uploadError.value = true;
  }

}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const handleSubmit = () => {
  console.log(emailValue.value)
  const isEmail = validateEmail(emailValue.value)
  if (isEmail) {
    //触发埋点
    localStorage.setItem("isSendEmail", true)
    reportData("toolkits_email_submit", { email_address: emailValue.value })
    emailValueStatus.value = ""
    visible.value = false
  } else {
    emailValueStatus.value = "error"
  }
}
const handleClick = () => {
  console.log("click")
  visible.value = true;
}
const handleCancel = () => {
  z
  sessionStorage.setItem("isSendEmail", true)
}
const handleSelectImage = (item) => {
  //
  sessionStorage.setItem("sourceImageId", `default_image_${item.id}`);
  currentSelectItem.value = item;
  emits("changSelectImage", item.src);
};

const handleBeforeUpload = (file) => {
  return new Promise((resolve, reject) => {
    this.$confirm({
      content: '请确认导入年份是否一致111？',
      onOk: () => {
        console.log('ok ')
        resolve(file);
      },
      onCancel: () => {
        console.log('cancel')
        reject(file);
      },
    })
  });

};

const handleMouseEnter = () => {
  isHover.value = true
}

const handleMouseleave = () => {
  isHover.value = false
}

</script>
<style lang="less" scoped >
.upload-wrap {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 99;
  -webkit-user-drag: none;
}

.upload-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  .upload-box {
    width: 100%;
    height: 100%;
    position: relative;
    // background: #ffffff;
  }
}

.imageSelect-container {
  margin-top: 54px;
}

.image-select-tip {
  margin-bottom: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Helvetica Neue";
  color: #8f959e;
}

.image-container {
  display: flex;
  justify-content: center;
}

.image-box {
  position: relative;
  margin-right: 20px;

}

.mask-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
  height: 110px;
  border-radius: 6px;
  background-color: #000;
  opacity: 0;
  transition: all 0.3s;
  cursor: pointer;
  // display: none;

  &:hover {
    opacity: 0.3;
  }
}

.upload-placeholder {
  height: 88px;
  position: absolute;
  width: 600px;
  top: 0px;
  cursor: pointer;
}

:global(.modal .ant-modal-header) {
  padding: 0;
  background: #060606;
  height: 118px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

:global(.modal .ant-modal-content) {
  border-radius: 4px;
}

.bg-image {
  position: absolute;
  z-index: 1;
}

.email-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  position: relative;
  z-index: 999;
  top: 47px;

  img {
    margin-right: 10px;
  }
}

:global(.modal .ant-modal-body) {
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

:global(.modal .ant-modal-close-icon) {
  display: none;
}

.error-msg {
  text-align: left;
  color: #F54A45;
}

:global(.modal .ant-modal-body p) {
  text-align: left;
}

:global(.modal .email-input) {
  border-radius: 200px;
  border: 1px solid #BBBFC4;
  height: 46px;
  padding-left: 24px;
}

:global(.input-item) {
  margin-bottom: 10px;
}

:global(.modal .email-submit) {
  background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
  border-radius: 200px;
  margin-top: 24px;
  width: 120px;
  height: 36px;
}

.image-item {
  width: 110px;
  height: 110px;
  background: #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  -webkit-user-drag: none;
  object-fit: cover;
}

:global(.ant-upload.ant-upload-drag) {
  border: none !important;
  border-radius: 6px;
}

:global(.upload-container .ant-upload.ant-upload-drag .ant-upload-text) {
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #444444;
  border-radius: 6px;


}

.ant-upload-max-size-text {
  margin-top: 6px;
  color: #8F959E;
}

:global(.upload-container .ant-upload.ant-upload-drag .ant-upload-text:hover) {
  background-color: #f7f7f7;
}

:global(.upload-container .ant-upload.ant-upload-drag-hover .ant-upload-text) {
  background-color: #f7f7f7;
  border-radius: 6px;
}

:global(.upload-container .ant-upload.ant-upload-drag .ant-upload) {
  padding: 0;
}

.progress-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-upload-h2-text {
  font-size: 14px;
  font-weight: 400;
}

.progress-box {
  width: 300px;

  :global(.ant-progress-inner) {
    background-color: #ededed !important;
    border-radius: 0;
  }

  :global(.ant-progress-bg) {
    background-color: #5a5a5a !important;
    border-radius: 0 !important;
  }
}

.ant-message-notice {
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.ant-message-notice-content) {
  background: #FEF1F1;
  border: 1px solid #F54A45;
  box-shadow: 0px 4px 8px rgba(31, 35, 41, 0.1);
  border-radius: 4px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 54px;
  line-height: 22px;
  padding: 0 20px;
  align-items: center;

  /* identical to box height, or 157% */

  color: #1F2329;

  .ant-message-notice-content {
    height: 100%;
    line-height: 54px;
  }
}
</style>
    
