<template>
  <div class="progress-wrap">
    <div class="progress-inner">
      <div class="progress-nums"></div>
    </div>
  </div>

</template >

<script>
export default {
  name: "HairSalonBar",
  props: {
    width: {
      type: String | Number,
      required: true
    },
    height: {
      type: String | Number,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
/*进度条*/
.progress-wrap {
  width: 100%;
  height: 12px;
  border-radius: 8px;
  position: relative;
}

.progress-wrap span {
  position: absolute;
  left: 50%;
  top: -20px;
  color: #000;
}

/*进度条底层背景样式*/
.progress-inner {
  height: inherit;
  background: #878787;
  border-radius: 8px;
}

/*进度层样式效果，使用动画效果*/
.progress-nums {
  width: 100%;
  height: inherit;
  border-radius: 6px;
  background: linear-gradient(-45deg, rgba(255, 255, 255, .25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .25) 50%, rgba(255, 255, 255, .25) 75%, transparent 75%, transparent);
  background-size: 16px 16px;
  animation: panoramic 30s linear infinite;
}

/*定义动画*/
@keyframes panoramic {
  to {
    background-position: 200% 0;
  }
}
</style>

