import Compressor from "compressorjs";
var md5 = require("md5");
import { fileUploadPre, uploadFile } from "../../api/image-filter";
import { buryPointReport } from "../../api/image-filter";
import axios from "axios";
import { getSessionsSource, getDeviceType } from "../index.js";

export function compressorImage (file, eventName) {
  if (!file) {
    return;
  }

  new Compressor(file, {
    quality: 0.4,
    maxWidth: 500,
    maxHeight: 500,
    convertSize: 5000000,
    mimeType: "image/webp",
    convertTypes: ["image/webp"],
    success (result) {
      uploadImage(result, eventName);
    },
    error (err) {
      console.log(err.message);
    },
  });
}

const uploadImageData = (eventName, imageId) => {
  const timestamp = Date.now();
  const deviceId = localStorage.getItem("browserId");
  const sessionId = sessionStorage.getItem("sessionId");
  const signId = md5(1 + deviceId + timestamp + eventName + "gJUyT9qGK");
  //图片id
  const params = {
    app_id: 1,
    app_name: "ai_tools",
    user_id: "",
    device_id: deviceId, //当前设备浏览器唯一id
    session_id: sessionId, //当前浏览器页面的sessionid
    timestamp: timestamp, // 当前时间戳，13 位毫秒级
    device_type: getDeviceType(),
    session_source: getSessionsSource(),
    event: {
      event_name: eventName,
      properties: {
        source_image_id: imageId,
      },
    },
    sign: signId,
  };
  buryPointReport({ ...params });
};

const uploadImage = (result, eventName) => {
  const timestamp = Date.now();

  const uploadsign = md5(".webp" + timestamp + "gJUyT9qGK");
  const uploadParams = {
    extension: ".webp",
    timestamp: timestamp,
    sign: uploadsign,
  };
  const formData = new FormData();

  fileUploadPre(uploadParams).then((res) => {
    const data = res.data;
    if (data.code == 0) {
      const uploadData = data.data;
      const { url, fields } = uploadData;

      //把返参中的key作为image-id
      sessionStorage.setItem("sourceImageId", fields.key);
      formData.append("key", fields.key);
      formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
      formData.append("policy", fields.policy);
      formData.append("signature", fields.signature);

      formData.append("file", result);
      uploadImageData(eventName, fields.key);
      uploadFile(url, formData);
    }
  });
};
